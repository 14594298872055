export default {
  methods: {
    validateName(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入方案名称"));
      }
      callback();
    },
    validatePrice(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入货币金额"));
      } else if (value === undefined) {
        callback(new Error("hello"));
      }
      callback();
    },
    validateAmount(rule, value, callback) {
      if (!value) {
        callback(new Error("请输入积分"));
      } else if (value < 1) {
        callback(new Error("积分须大于等于1"));
      }
      callback();
    },

    validForm(callback, form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          callback();
        }
      });
    },
  },
};
