<template>
  <div v-if="hasResource('asset_publish_review')" @click="handleColorShow">
    <div class="publish-content" v-loading="categoryLoading">
      <div style="width:18%;" class="left-content">
        <div
          class="
              fz-18
              cursor-pointer
              width-107
              height-46
              mg-b-20
              text-back
              mg-t-50
            "
          @click="back"
        >
          <i class="iconfont fz-18 black">
            &#xe647;
            <span class="back-text">返回</span>
          </i>
        </div>
        <div class="publish-title">
          <span class="fw-300 fz-18 line-height-48">发布资产</span>
          <span class="bold fz-20 mg-l-20 line-height-48">
            {{ assetName }}
          </span>
        </div>
        <div v-for="(item, index) in assetImg" :key="index" class="asset-img">
          <img :src="item.url + '?imageMogr2/thumbnail/x512/ignore-error/1/interlace/1'" />
        </div>
      </div>
      <div style="width:80%;marginLeft:20%">
        <el-form
          :inline="true"
          :label-position="labelPosition"
          label-width="100px"
          :model="publishAsset"
          :rules="rules"
          ref="assetForm"
        >
          <div class="right-content">
            <div class="pd-l-70 pd-t-50 pd-b-50">
              <div class="fz-24 line-height-48 chinese-bold col-00">
                市场属性
              </div>

              <el-row :gutter="24">
                <el-col :span="8">
                  <!-- <div class="mg-t-30">
                    <el-form-item
                      label="发布市场"
                      class="name-input"
                      :required="true"
                    >
                      <el-input
                        v-model="publishAsset.publishMarket"
                        :disabled="true"
                      ></el-input>
                    </el-form-item>
                  </div> -->
                  <div class="mg-t-30">
                    <!-- <el-form-item
                      label="下载价格"
                      class="name-input"
                      prop="cost"
                    >
                      <el-input
                        v-model="publishAsset.cost"
                        placeholder="请填写整数"
                      ></el-input>
                    </el-form-item> -->
                  </div>
                 <div>
                    <el-form-item
                      label="英文名称"
                      class="name-input"
                      prop="name"
                    >
                      <el-input
                        v-model="publishAsset.name"
                        placeholder="请输入英文名称"
                      ></el-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="mg-t-30">
                    <el-form-item label="资产大小" class="name-input">
                      <el-input
                        v-model="publishAsset.resourceSize"
                        :disabled="true"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item
                      label="发布时间"
                      label-width="100px"
                      class="name-input"
                      :required="true"
                    >
                      <el-select
                        v-model="value"
                        placeholder="请选择"
                        @change="handleTime"
                      >
                        <el-option
                          v-for="item in publishAsset.publishTime"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-date-picker
                      class="select-date"
                      ref="dataPicker"
                      v-if="isTrue"
                      v-model="publishDate"
                      type="datetime"
                      :picker-options="pickerOptions"
                      value-format="timestamp"
                      placeholder="选择日期时间"
                    ></el-date-picker>
                  </div>
                  <div>
                    <el-form-item
                      label="中文名称"
                      class="name-input"
                      prop="chinese_name"
                    >
                      <el-input
                        v-model="publishAsset.chinese_name"
                        placeholder="请输入中文名称"
                      ></el-input>
                    </el-form-item>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="show-property">
              <div class="fz-24 line-height-48 chinese-bold col-00 mg-b-20">
                展示属性
              </div>
              <div>
                <el-form-item label="*3S效果">
                  <el-radio-group v-model="publishAssetProperty.is_sss">
                    <el-radio :label="1">需要</el-radio>
                    <el-radio :label="0">不需要</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="*多模型资产">
                  <el-radio-group v-model="publishAssetProperty.is_grouped">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="*全屏放大">
                  <el-radio-group v-model="publishAssetProperty.is_full_screen">
                    <el-radio :label="1">支持</el-radio>
                    <el-radio :label="0">不支持</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </div>
            <model-type :asset="publishAsset" :is3D="is3D" />
            <div class="color-property">
              <div class="fz-24 line-height-48 chinese-bold col-00">
                颜色属性
              </div>
              <div class="display-flex">
                <div class="fz-14 mg-t-20 mg-r-30">资产颜色</div>
                <div class="mg-t-20 color-container">
                  <div
                    class="width-30 height-30 mg-tl-2"
                    :class="hasColor(color) ? 'active-color' : ''"
                    :key="color"
                    v-for="color in colors"
                    :style="`background:${color}`"
                    @click.stop="chooseColor(color, $event)"
                  >
                    <i
                      v-if="hasColor(color) && color != '#FFFFFF'"
                      class="iconfont white color-selected"
                      >&#xe607;</i
                    >
                    <i
                      v-else-if="hasColor(color) && color == '#FFFFFF'"
                      class="iconfont color-icon-white"
                      >&#xe69c;</i
                    >
                  </div>
                  <div v-if="this.colorShow" class="color-f56c fz-14">
                    颜色属性不能大于五个
                  </div>
                </div>
              </div>
            </div>
            <div class="add-classify">
                <assetClassify
                    :list="classifyList"
                    :tags="classificationName"
                    @onAdd="addClassification"
                    @onDel="delClassification"
                />
            </div>
            <!-- <div class="add-antistop">
              <div class="fz-24 line-height-48 chinese-bold col-00">
                添加关键词
                <span class="suggest">多个关键词可用英文逗号隔开</span>
              </div>
              <div class="mg-t-20 assetsTag">
                关键词名称
                <el-input
                  class="inputTag"
                  placeholder="请输入 "
                  v-model="antistopInput"
                >
                  <i
                    slot="suffix"
                    class="el-input__icon el-icon-plus iconStyle"
                    @click="addAntistop"
                  ></i>
                </el-input>
              </div>

              <div class="addStyle">
                <el-tag
                  class="mg-l-10"
                  :key="tag"
                  v-for="tag in antistopName"
                  closable
                  :disable-transitions="false"
                  @close="antistopRemove(tag)"
                  >{{ tag }}</el-tag
                >
              </div>
            </div> -->
            <assetAddTags :tags="tags" :chineseTags="chineseTags" />
            <div class="add-remark">
              <div class="fz-24 line-height-48 chinese-bold col-00">
                添加备注
              </div>
              <div class="mg-t-20">
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="textarea"
                  maxlength="200"
                ></el-input>
              </div>
              <div class="float mg-t-50">
                <el-button
                  class="default-button"
                  @click="sure"
                  :loading="btnLoading"
                  >确认</el-button
                >
                <el-button class="default-button" @click="back">取消</el-button>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../../api/assets";
import Api from "../../../api/asset";
import classifyFilter from '@/mixins/asset/classify.js';
import intergalsss from "@/mixins/intergal";
import modelType from '../_common/modelType/modelType.vue';
import assetAddTags from '../_common/assetAddTags/assetAddTags.vue';
import assetClassify from '../_common/assetClassify/assetClassify.vue';
export default {
  components: { modelType, assetAddTags, assetClassify },
  mixins: [intergalsss, classifyFilter],
  data() {
    const blurText = (rule, value, callback) => {
      const reg = /^[0-9]*$/;
      if (!reg.test(value)) {
        callback(new Error("请输入整数"));
      }
      callback();
    };

     const nameRegex = (rule, value, callback) => {
       if (value.length && value.length > 36) {
           callback(new Error('名称不能超过36'));
        }
        const reg = /^[\sa-zA-Z-]*$/;
        if (!reg.test(value)) {
            callback(new Error("仅支持字母,空格,中划线"));
        }
      callback();
    };
    const name_cnRegex = (rule, value, callback) => {
       if (value.length && value.length > 18) {
           callback(new Error('名称不能超过18'));
        }
        const reg = /^[\s\u4e00-\u9fa5-]*$/;
        if (!reg.test(value)) {
            callback(new Error("仅支持中文,空格,中划线"));
        }
      callback();
    };
    const sizeRegex = (rule, value, callback) => {
        if (value === '' && this.is3D) {
           callback(new Error('请输入长宽高'));
        }
        const reg = /^(\d+(\.\d+)?\*){2}\d+(\.\d+)?$/;
        if (value !== '' && !reg.test(value)) {
            callback(new Error("格式应为“长*宽*高”，其中，“长”、“宽”、“高”均为数字"));
        }
        callback();
    };
    const modelStateRegex = (rule, value, callback) => {
        if (value === '' && this.is3D) {
            callback(new Error('请选择是否封闭'));
        }
        callback();
    };
    return {
      // 资产名称
      assetName: "",
      labelPosition: "left",
      publishAsset: {
        publishMarket: "全部市场",
        cost: "",
        publishTime: [
          {
            value: 1,
            label: "审批完成后立即发布",
          },
          {
            value: 0,
            label: "审批完成后定时发布",
          },
        ],
        resourceSize: "",
        name:'',
        chinese_name:'',
        type: '', //资产类型
        size: '', // 长宽高
        model_state: '', // 是否封闭
      },
      publishAssetProperty: {
        is_sss: 1,
        is_grouped: 0,
        is_full_screen: 1,
      },
      value: "",
    //   tagName: "",
    //   tagName_cn: "",
      antistopInput: "",
      textarea: "",
      publishDate: "",
      isTrue: false,
      // 分类
      categories: [],
    //   cates: [],
      classificationName: [],
      classifyList: [],
      categoriesList: [],
      // 添加标签
      tags: [], // 英文标签
      chineseTags: [], // 中文标签
      // 添加关键词
      itemAntistop: 0,
      antistopName: [],
      assetImg: "",
      categoryLoading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      activeColor: [],
      colors: [
        "#B8342B",
        "#FC6B33",
        "#F2CE58",
        "#519662",
        "#1E3783",
        "#593562",
        "#242424",
        "#FFFFFF",
        "#ABAEAA",
      ],
      colorShow: false,
      btnLoading: false,
      rules: {
        cost: [
          { required: true, message: "请输入价格", trigger: "blur" },
          {
            validator: blurText,
            trigger: "blur",
          },
        ],
        name: [
        { required: true, message: "请输入英文名称", trigger: "blur" },
        { validator: nameRegex, trigger: "blur"}
        ],
        chinese_name: [
        { required: true, message: "请输入中文名称", trigger: "blur" },
        { validator: name_cnRegex, trigger: "blur",}
        ],
        type: [
          { required: true, message: "请选择资产类型", trigger: "change" },
        ],
        size: [
          { validator: sizeRegex, trigger: ['blur', 'change'] }
        ],
        model_state: [
          { validator: modelStateRegex, trigger: "change" }
        ],
      },
    };
  },
  computed: {
    is3D() {
      // 资产类型是3D模型
      return this.publishAsset.type === '3D Assets';
    }
  },
  methods: {
    init() {
      this.publishDate = "";
      let data = this.$route.query.id;
      api.assetsInfo(data).then((res) => {
        if (res.data.code === 10200) {
          const resData = res.data.data;
          this.assetName = res.data.data.name;
          this.assetImg = res.data.data.previews;
          this.publishAsset.resourceSize = res.data.data.resource_total_size;
          this.publishAsset.name = this.assetName;
          this.publishAsset.chinese_name = resData.chinese_name;
          this.publishAsset.type = resData.type;
          this.publishAsset.size = resData.size;
          this.publishAsset.model_state = resData.model_state;
        }
      });
    },
    hasColor(color) {
      let activeColor = this.activeColor;
      if (activeColor.indexOf(color) > -1) {
        return true;
      }
      return false;
    },
    categoryList() {
      api.categoryList().then((res) => {
        if (res.data.code === 10200) {
          this.classifyList = this.handleFilterData(res.data.data.items);
        }
      });
    },
    getReviewInfo() {
      this.categoryLoading = true;
      let data = {
        id: this.$route.query.id,
        market: "US",
      };
      Api.reviewInfo(data).then((res) => {
        if (res.data.code === 10200) {
          this.publishAsset.resourceSize =
            res.data.data.asset.resource_total_size;
          this.categoryLoading = false;
          let data = res.data.data.asset;
          this.assetName = res.data.data.asset_name;
          this.tags = data.tags || [];
          // 获取中文标签
          this.chineseTags = data.chinese_tags || []
          this.antistopName = data.search_tags || [];
          this.itemAntistop = this.antistopName.length;
          this.activeColor = res.data.data.color || [];
          let categories = data.categories;
          this.categoriesList = data.categories;
          let resCategory = [];
        //   let agreeCate = [];
          let i = 0;
          for (let key in categories) {
            let categoryName = categories[key]["label"];
            // let cate = categories[key]["id"];
            resCategory.push(categoryName);
            // agreeCate.push(cate);
            i++;
          }
        //   this.classificationTag = i;
          this.classificationName = resCategory;
        //   this.cates = data.category_ids;
          this.categories = data.category_ids;
          this.textarea = data.publish_note;
          this.publishAsset.cost = data.cost;
          // this.firstValue = data.
          this.value = data.instant_publish;
          this.isTrue = data.instant_publish == 0 ? true : false;
          this.publishDate = data.published_at ? data.published_at * 1000 : '';
          this.publishAsset.name = data.name;
          this.publishAsset.chinese_name = data.chinese_name;
          this.publishAsset.type = data.type;
          this.publishAsset.size = data.size;
          this.publishAsset.model_state = data.model_state;
        }
      });
    },
    back() {
      this.$router.push("/assets/all");
    },
    sure() {
      if (this.value === "" || this.value === 1) {
        this.publishDate = "";
      }
    //   let categories = [];
    //   if (this.$route.query.type === "approval") {
    //     categories = categories.concat(this.cates);
    //     categories = categories.concat(this.categories);
    //     this.value;
    //   } else {
    //     categories = this.categories;
    //   }
      if (this.value !==1 && !this.publishDate) {
       this.$$warning("请选择发布时间");
        return;
      }
      if (this.classificationName.length === 0) {
        this.$$warning("请添加分类");
        return;
      }

      this.$refs["assetForm"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          let data = {
            market: "US",
            id: this.$route.query.id,
            tags: this.tags,
            chinese_tags: this.chineseTags,
            cost: this.publishAsset.cost,
            search_tags: this.antistopName,
            categories: this.categories,
            instant_publish: this.value,
            published_time: this.publishDate ? this.publishDate / 1000 : '',
            comment: this.textarea,
            color: this.activeColor,
            is_sss: this.publishAssetProperty.is_sss,
            is_grouped: this.publishAssetProperty.is_grouped,
            is_full_screen: this.publishAssetProperty.is_full_screen,
            chinese_name: this.publishAsset.chinese_name,
            name: this.publishAsset.name,
            type: this.publishAsset.type,
            size: this.publishAsset.size,
            model_state: this.publishAsset.model_state
          };
          if (this.$route.query.type == "approval") {
            Api.resetAssets(data).then((resp) => {
              if (resp.data.code == 10200) {
                this.$$success("成功");
                this.$router.push("/assets/approval");
              } else {
                this.$$error(res.data.message);
              }
              this.btnLoading = false;
            });
          } else {
            api.publishReview(data).then((res) => {
              if (res.data.code === 10200) {
                this.$router.push("/assets/approval");
              } else {
                this.$$error(res.data.message);
              }
              this.btnLoading = false;
            });
          }
        } else {
            this.handleScrollView()
        }
      });
    },
    /**
     * 滚动到报错位置
    */
    handleScrollView() {
        this.$nextTick(() => {
            const isError = document.getElementsByClassName('is-error')
            // 滚动到指定节点
            isError[0].scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            })
        });
    },

    // 发布时间
    handleTime(event) {
      if (event === 0) {
        this.isTrue = true;
      } else {
        this.isTrue = false;
      }
    },
    addClassification({value, label}) {
      this.classificationName.push(label);
      this.categoriesList.push({
        label: label,
        id: value
      })
      this.categories.push(value);
    },
    delClassification(index) {
      this.categoriesList.forEach((item, ids) => {
        if (item.label == index) {
          this.categories.forEach((el, els) => {
            if (item.id == el) {
              this.categories.splice(els, 1);
            }
          });
        }
      });
      this.classificationName.splice(this.classificationName.indexOf(index), 1);
    },
    // 添加关键词
    addAntistop() {
      if (this.antistopInput === "") {
        this.$$error("关键词名称不能为空");
        return false;
      }
      if (this.antistopInput.length > 10) {
        this.$$error("关键词名称不能大于10位");
        return false;
      }
      // this.itemAntistop += 1;
      this.antistopName.push(this.antistopInput);
      this.antistopInput = "";
    },

    // 移除
    antistopRemove(index) {
      this.antistopName.splice(this.antistopName.indexOf(index), 1);
    },
    chooseColor(color) {
      let index = this.activeColor.indexOf(color);
      if (index > -1) {
        this.activeColor.splice(index, 1);
        if (this.activeColor.length < 5) {
          this.colorShow = false;
        }
      } else {
        if (this.activeColor.length > 4) {
          this.colorShow = true;
          return;
        }
        this.activeColor.push(color);
      }
    },
    handleColorShow() {
      if (this.activeColor.length > 4) {
        this.colorShow = false;
      }
    },
  },
  mounted() {
    this.categoryList();
    if (this.$route.query.type === "approval") {
      setTimeout(() => {
        this.getReviewInfo();
      }, 20);
    } else {
      this.init();
    }
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="less">
@import "./scss/publish.less";
</style>
